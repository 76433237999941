<template>
  <div class="parent-register">
    <div class="holder">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-18 col-lg-12">
            <h2 class="text-center mt-3">تسجيل حساب</h2>
            <div class="form-wrapper">
              <!-- <p>للوصول إلى قائمة الرغبات ودفتر العناوين وتفضيلات الاتصال الخاصة بك وللاستفادة من الخروج السريع ، قم بإنشاء حساب معنا الآن.</p> -->
              <form action="#" @submit.prevent="VALIDATE_REGISTER({ name, phone, email, password, conPassword, selectValue })">
                <div>
                  <div class="flex-group">
                    <div class="form-group shadow-redis-input">
                      <input type="text" class="form-control" placeholder="الاسم كامل" v-model="name" required />
                    </div>
                    <div class="form-group shadow-redis-input">
                      <input type="text" class="form-control" placeholder="رقم الهاتف" v-model="phone" required />
                    </div>
                  </div>
                  <Transition name="bounce">
                    <div class="alert alert-error" role="alert" v-if="errorValidate.errorPhone">
                      {{ errorValidate.errorPhone }}
                    </div>
                  </Transition>
                  <div class="flex-group">
                    <div class="form-group shadow-redis-input">
                      <input type="email" class="form-control" placeholder="البريد الإلكتروني" v-model="email" required />
                    </div>
                    <div class="form-group shadow-redis-input">
                      <input type="password" class="form-control" placeholder="كلمه السر" v-model="password" required />
                    </div>
                  </div>
                  <div class="flex-group">
                    <Transition name="bounce">
                      <div class="alert alert-error mt-1" role="alert" v-show="errorValidate.errorEmail">
                        {{ errorValidate.errorEmail }}
                      </div>
                    </Transition>
                    <Transition name="bounce">
                      <div class="alert alert-error" role="alert" v-if="errorValidate.errorPassword">
                        {{ errorValidate.errorPassword }}
                      </div>
                    </Transition>
                  </div>
                  <div class="flex-group">
                    <div class="form-group shadow-redis-input">
                      <input type="password" class="form-control" placeholder="تأكيد كلمة السر" v-model="conPassword" required />
                    </div>

                    <div class="form-group shadow-redis-input select-wrapper">
                      <select class="form-control" v-model="selectValue" id="books">
                        <option :value="option.id" v-for="option in bookNameRegister" :key="option.id">
                          {{ option.name }}
                        </option>
                      </select>
                      <label for="books" class="choose-book">اختر ملزمة</label>
                    </div>
                  </div>
                  <Transition name="bounce">
                    <div class="alert alert-error" role="alert" v-if="errorValidate.errorPassConPass">
                      {{ errorValidate.errorPassConPass }}
                    </div>
                  </Transition>
                  <div class="flex-group mt-50">
                    <div class="text-center">
                      <button class="btn">إنشاء حساب جديد</button>
                    </div>
                    <div class="text-center">
                      <router-link to="/login" class="btn">تسجيل الدخول</router-link>
                    </div>
                  </div>
                  <Transition name="slide-fade">
                    <p class="success-login" v-if="message" v-text="message"></p>
                  </Transition>
                </div>
              </form>
              <!-- <div class="text-center">
                  <button class="btn" @click="CLEAR_USER_DATA">Click</button>
                </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Register",
  data() {
    return {
      name: "",
      phone: null,
      email: "",
      password: null,
      conPassword: null,
      selectValue: "",
    };
  },
  computed: {
    ...mapState("Books", ["bookNameRegister"]),
    ...mapState("Auth", ["errorValidate", "message"]),
  },
  mounted() {
    this.showHideLabel();
    this.GET_BOOK_NAME_REGISTER();
  },
  methods: {
    // ...mapActions("Register", ["VALIDATE_REGISTER"]),
    ...mapActions("Books", ["GET_BOOK_NAME_REGISTER"]),
    ...mapActions("Auth", ["VALIDATE_REGISTER", "CLEAR_USER_DATA"]),
    showHideLabel() {
      let selected = document.getElementById("books");
      selected.addEventListener("focus", (e) => {
        if (e.target.value) {
          selected.classList.add("active");
        } else {
          selected.classList.remove("active");
        }
      });
      selected.addEventListener("blur", (e) => {
        if (e.target.value) {
          selected.classList.add("active");
        } else {
          selected.classList.remove("active");
        }
      });
    },
  },
};
// 0512345678
</script>

<style lang="scss" scoped>
.choose-book {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s;
}
#books {
  z-index: 1;
  position: relative;
  background: transparent;
  margin-bottom: 10px;
  text-align: center;
  &:focus + label,
  &.active + label {
    opacity: 0;
  }
}
.mt-50 {
  margin-top: 50px;
}
</style>
