<template>
  <div class="parent-froduct" v-if="book.id">
    <div class="holder">
      <h1 class="prd-block_title mt-3">{{ book.name }}</h1>
      <div class="container js-prd-gallery" id="prdGallery">
        <!-- Slider -->
        <Slider :book="book" />
        <!-- Info -->
        <Info :book="book" :id="id" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Slider from "@/components/Book/Slider.vue";
import Info from "@/components/Book/Info.vue";

// slider

export default {
  name: "BookViews",
  props: ["id"],

  computed: {
    ...mapState("Books", ["book"]),
  },
  async created() {
    document.querySelector(".loader-wrapper").style.display = "grid";
    await this.getUserID(this.id);
    await this.GET_CATEGORIES_FROM_LS();
    await this.GET_BOOK(this.id);
    await this.HIDE_LOADER();
  },
  methods: {
    ...mapMutations("Books", ["getUserID"]),
    ...mapActions("Books", ["GET_BOOK"]),
    ...mapActions("Cart", ["GET_CATEGORIES_FROM_LS"]),
    ...mapActions("Global", ["HIDE_LOADER"]),
  },
  components: { Slider, Info },
};
</script>

<style lang="scss" scoped></style>
