<template>
  <div class="row prd-block con-images prd-block--prv-left">
    <div class="col-md-8 col-lg-10 aside--sticky js-sticky-collision con-images2">
      <div class="aside-content">
        <!-- Product Gallery main-slider-->
        <div class="mb-2 js-prd-m-holder"></div>
        <div class="prd-block_main-image">
          <div class="prd-block_main-image-holder" id="prdMainImage">
            <!-- ``` parent slider  -->
            <div class="main-slider">
              <div class="slider-for">
                <div class="product1"><img :src="book.image" alt="" /></div>
                <div class="product1"><img :src="book.image" alt="" /></div>
                <div class="product1"><img :src="book.image" alt="" /></div>
                <div class="product1" v-for="(imgSlider, i) in book.slider" :key="i">
                  <img :src="imgSlider" alt="" />
                </div>
              </div>
              <div class="slider-nav">
                <div class="product2"><img :src="book.image" alt="" /></div>
                <div class="product2"><img :src="book.image" alt="" /></div>
                <div class="product2"><img :src="book.image" alt="" /></div>
                <div class="product2" v-for="(imgSlider, i) in book.slider" :key="i">
                  <img :src="imgSlider" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="prd-block_label-sale-squared justify-content-center align-items-center">
            <span>تخفيض</span>
          </div>
        </div>
      </div>
      <!-- ``` child slider -->

      <!-- /Product Gallery ``` -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: ["book"],
  async mounted() {
    await this.slickSlider();
  },
  methods: {
    slickSlider() {
      $(document).ready(function () {
        $(".slider-for").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: ".slider-nav",
          rtl: true,
        });
        $(".slider-nav").slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: ".slider-for",
          dots: true,
          centerMode: true,
          focusOnSelect: true,
          rtl: true,
        });
      });
    },
  },
};
</script>

<style lang="scss">
body {
  // background-color: #101010 !important;
  // color: #fff;

  .slider {
    width: 50%;
    margin: 100px auto;
  }

  .slick-slider {
    direction: rtl !important;
  }
  .slick-slide {
    margin: 0px 20px;
  }

  .slick-slide img {
    width: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    color: black;
  }

  .slick-slide {
    transition: all ease-in-out 0.3s;
    opacity: 0.2;
  }

  .slick-active {
    opacity: 0.5;
  }

  .slick-current {
    opacity: 1;
  }
  .main-slider {
    .slider-for {
      width: 100%;
      // margin: 100px auto;
      img {
        width: 100%;
      }
    }
    .slider-nav {
      width: 100%;
      margin: 4px;
      padding: 20px;
      img {
        width: 100%;
      }
    }
  }
  .aside-content {
    justify-content: center;
  }
}

.prd-block_qty {
  display: flex;
  justify-content: space-around;
}

.con-images2 {
  max-width: 100% !important;
}

.con-images {
  min-width: 50% !important;
}
.js-prd-gallery {
  display: flex;
}
.prd-block_title {
  margin-bottom: 40px !important;
  text-align: center !important;
  font-size: 30px !important;
}
.handel-click.active {
  background: #eee;
  color: #000;
  pointer-events: none;
  &:focus,
  &:active,
  &:hover {
    background: #eee !important;
    color: #000 !important;
  }
}
/* .slick-next {
  right: 20px !important;
} */
</style>
