import router from "../../router/index";
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {
    redirectTo(state, payload) {
      router.push({ name: payload });
    },
  },
  // @click="$event.target.classList.add('active')"
  actions: {
    HANDEL_CLICK_ADD_TO_CART(state, buttons) {
      buttons.forEach((ele) => {
        ele.setAttribute("disabled", true);
        ele.innerText = "تم اضافتة الي السلة";
      });
    },
    // animated images add to cart and cart
    async ANIMATE_ADD_TO_CART({ dispatch }, { srcImage, ele }) {
      dispatch("Books/GET_IS_ACTIVE", { ele }, { root: true });
      let imgNew = document.createElement("img");
      imgNew.src = srcImage;
      window.matchMedia("(max-width: 768px)").matches
        ? imgNew.classList.add("animate-img-small")
        : imgNew.classList.add("animate-img");

      document.body.appendChild(imgNew);
      let cartAnimated;
      window.matchMedia("(max-width: 768px)").matches
        ? (cartAnimated = document.querySelector(".cart-icon a"))
        : (cartAnimated = document.querySelector(".open-cart img"));
      cartAnimated.classList.add("active");
      setTimeout(() => {
        imgNew.remove();
        setTimeout(() => {
          cartAnimated.classList.remove("active");
        }, 800);
      }, 1000);
      dispatch("HANDEL_CLICK_ADD_TO_CART", document.querySelectorAll(".handel-click.active"));
    },
    // Cut Text
    RUN_CUT_TEXT() {
      $(function () {
        $.fn.cutText = function () {
          let allText = [];
          let textCut = [];
          $(this).each(function (index) {
            allText.push($(this).text());
            textCut.push($(this).text().substring(0, 40));
            $(this).text(textCut[index]);
            $(this).append("<span title='Show All'> ... </span>");
            $(this).on("click", function () {
              $(this).toggleClass("active");
              if ($(this).hasClass("active")) {
                $(this).text(allText[index]);
                // $(this).append("<span title='Cut Text'><<<</span>");
              } else {
                $(this).text(textCut[index]);
                $(this).append("<span title='Show All'> ... </span>");
              }
            });
          });
        };
        let allTitleBooks = $(".prd-title a");
        allTitleBooks.each(function () {
          let text = $(this).text().length;
          if (text < 18) {
            $(this).addClass("mx-button");
          }
        });
        $(".cut-text").cutText();
      });
    },
    // Hide Loader
    HIDE_LOADER() {
      $(function () {
        let loader = $(".loader-wrapper");
        loader.delay(1000).fadeOut(1000, function () {
          // this.remove();
        });
      });
    },
    // menu header
    MENU_HEADER() {
      let header = document.querySelector("header");
      let headerHeight = header.clientHeight;
      let menu = document.querySelectorAll(".mmenu-js");
      menu.forEach((ele) => {
        ele.style.top = `${headerHeight}px`;
      });
      let helperFun = (num) => {
        if (window.scrollY >= num) {
          if (document.body.classList.contains("has-sticky")) {
          } else {
            document.body.classList.add("has-sticky");
            menu.forEach((ele) => {
              ele.style.top = `${headerHeight - 26}px`;
            });
          }
        } else {
          document.body.classList.remove("has-sticky");
          menu.forEach((ele) => {
            ele.style.top = `${headerHeight}px`;
          });
        }
      };
      window.onscroll = () => {
        if (window.matchMedia("(max-width: 768px)").matches) {
          helperFun(26);
        } else {
          helperFun(40);
        }
      };
    },
  },
};
