import { createStore } from "vuex";
// import axios from "axios";
import Global from "./modules/Global";
import Books from "./modules/Books";
import Cart from "./modules/Cart";
import Auth from "./modules/Auth";
import Rates from "./modules/Rates";
import MyBooks from "./modules/MyBooks";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Global,
    Books,
    Cart,
    Auth,
    Rates,
    MyBooks,
  },
});
