<template>
  <section class="con-swiper-2">
    <div class="title-section">
      <h2 class="h1-title">الحقائب التعليمية</h2>
      <p>ملازم وكتب الرخصة المهنية عام والتخصصات المختلفة</p>
    </div>
    <!-- Categories Slider  ```-->
    <swiper
      class="mySwiper-2 swiper prd-grid data-to-show-4 data-to-show-md-2 data-to-show-sm-2 data-to-show-xs-1 js-product-grid-sm parallax-slider"
      :slidesPerView="4"
      :spaceBetween="0"
      :slidesPerGroup="1"
      :loop="true"
      :autoplay="true"
      :pauseOnMouseEnter="true"
      :loopFillGroupWithBlank="true"
      :navigation="false"
      :pagination="pagination"
      :modules="modules_2"
    >
      <swiper-slide
        :data-swiper-autoplay="5000"
        class="swiper-slide prd prd--style2 prd-labels--max prd-labels-shadow shadow-redis"
        v-for="book in categories"
        :key="book.id"
      >
        <div class="prd-inside">
          <div class="prd-img-area">
            <router-link
              @click="succeeded()"
              :to="{ name: 'Book', params: { id: book.id } }"
              class="prd-img image-hover-scale image-container"
            >
              <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                :data-src="book.image"
                alt="The Book 01"
                class="js-prd-img lazyload fade-up"
              />
              <div class="foxic-loader"></div>
              <div class="prd-big-squared-labels"></div>
            </router-link>
            <div class="prd-circle-labels">
              <router-link
                @click="succeeded()"
                :to="{ name: 'Book', params: { id: book.id } }"
                class="circle-label-qview prd-hide-mobile"
                ><i class="icon-eye"></i><span>عرض الكتاب</span></router-link
              >
            </div>
          </div>
          <div class="prd-info">
            <div class="prd-info-wrap">
              <h2 class="prd-title">
                <router-link @click="succeeded()" :to="{ name: 'Book', params: { id: book.id } }" class="d-block">{{
                  book.name
                }}</router-link>
              </h2>
              <!-- <div class="prd-description cut-text">
                    {{ book.description }}
                  </div> -->
              <div class="prd-block_description prd-block_info_item purchase-count">
                <h3>تم شراءه</h3>
                <p>{{ book.purchase_count }} مرة</p>
                <div class="mt-1"></div>
              </div>
            </div>
            <div class="prd-hovers">
              <div class="prd-circle-labels">
                <div>
                  <a href="#" class="circle-label-compare circle-label-wishlist--add js-add-wishlist mt-0" title="Add To Wishlist"
                    ><i class="icon-heart-stroke"></i></a
                  ><a
                    href="#"
                    class="circle-label-compare circle-label-wishlist--off js-remove-wishlist mt-0"
                    title="Remove From Wishlist"
                    ><i class="icon-heart-hover"></i
                  ></a>
                </div>
              </div>
              <div class="prd-price">
                <div class="price-new">{{ book.price }} ريال</div>
                <div class="prd-tag discount">{{ book.discount }} ريال</div>
              </div>
              <div class="prd-action">
                <div class="prd-action-left">
                  <form action="#">
                    <button
                      @click="(event) => addClassActive(event)"
                      @click.prevent.stop="
                        SET_CART_ID_TO_LS({ id: book.id, qty: 1 });
                        ANIMATE_ADD_TO_CART({ srcImage: book.image, ele: 'categories', event: $event.target });
                      "
                      class="btn js-prd-addtocart handel-click"
                      :class="{ active: book.isActive }"
                    >
                      <i class="fa fa-cart-plus"></i>
                      إضافة للسلة
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <!-- Categories in Mobile Screen -->
    <div
      class="categories-books prd-grid data-to-show-4 data-to-show-md-2 data-to-show-sm-2 data-to-show-xs-1 js-product-grid-sm parallax-slider"
    >
      <div
        class="swiper-slide prd prd--style2 prd-labels--max prd-labels-shadow shadow-redis"
        v-for="book in categories"
        :key="book.id"
      >
        <div class="prd-inside mb-3">
          <div class="prd-img-area">
            <router-link :to="{ name: 'Book', params: { id: book.id } }" class="prd-img image-hover-scale image-container">
              <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                :data-src="book.image"
                alt="The Book 01"
                class="js-prd-img lazyload fade-up"
              />
              <div class="foxic-loader"></div>
              <div class="prd-big-squared-labels"></div>
            </router-link>
          </div>
          <div class="prd-info">
            <div class="prd-info-wrap">
              <h2 class="prd-title">
                <router-link :to="{ name: 'Book', params: { id: book.id } }" class="d-block">{{ book.name }}</router-link>
              </h2>
              <!-- <div class="prd-description cut-text">
                    {{ book.description }}
                  </div> -->
              <div class="prd-block_description prd-block_info_item purchase-count">
                <h3>تم شراءه</h3>
                <p>{{ book.purchase_count }} مرة</p>
                <div class="mt-1"></div>
              </div>
            </div>
            <div class="prd-hovers">
              <div class="prd-price">
                <div class="price-new">{{ book.price }} ريال</div>
                <div class="prd-tag discount">{{ book.discount }} ريال</div>
              </div>
              <div class="prd-action">
                <div class="prd-action-left">
                  <form action="#">
                    <button
                      @click="(event) => addClassActive(event)"
                      @click.prevent.stop="
                        SET_CART_ID_TO_LS({ id: book.id, qty: 1 });
                        ANIMATE_ADD_TO_CART({ srcImage: book.image, ele: 'categories' });
                      "
                      class="btn js-prd-addtocart handel-click"
                      :class="{ active: book.isActive }"
                    >
                      <i class="fa fa-cart-plus"></i>
                      إضافة للسلة
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
// import required modules
import { Navigation, Autoplay, Pagination } from "swiper";
export default {
  name: "Swiper_2",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      },
      modules_2: [Navigation, Autoplay, Pagination],
    };
  },
  computed: {
    ...mapState("Books", ["categories"]),
  },
  async mounted() {
    await this.GET_CATEGORIES();
    await this.HANDEL_CLICK_ADD_TO_CART(document.querySelectorAll("button.active"));
  },
  methods: {
    ...mapActions("Books", ["GET_CATEGORIES"]),
    ...mapMutations("MyBooks", ["succeeded"]),
    ...mapActions("Cart", ["SET_CART_ID_TO_LS"]),
    ...mapActions("Global", ["ANIMATE_ADD_TO_CART", "HANDEL_CLICK_ADD_TO_CART"]),
    addClassActive(event) {
      event.target.classList.add("active");
    },
  },
};
</script>

<style lang="scss">
.mySwiper-2 {
  padding-bottom: 100px !important;
  padding-right: 6px !important;
}
.categories-books {
  display: none !important;
}
</style>
