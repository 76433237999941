import axios from "axios";
export default {
  namespaced: true,
  state: {
    MyBooks: false,
    modeSucceeded: true,
  },
  mutations: {
    succeeded(state) {
      if (location.pathname === "/my-books") {
        state.modeSucceeded = false;
      } else {
        state.modeSucceeded = true;
      }
    },
  },
  actions: {
    async GET_MY_BOOKS({ commit, state, rootState }) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${rootState.Auth.user.user.token}`;
      await axios.post(`/my-books`).then((res) => (state.MyBooks = res.data.books));
    },
  },
};

/* 

*/
