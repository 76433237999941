<template>
  <div class="col-5 my-form">
    <form v-if="!userID">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="الاسم كامل" v-model="name" required />
      </div>
      <div class="form-group">
        <input type="email" class="form-control" placeholder="البريد الإلكتروني" v-model="email" required />
      </div>
      <Transition name="bounce">
        <div class="alert alert-error" role="alert" v-show="errorValidate.errorEmail">
          {{ errorValidate.errorEmail }}
        </div>
      </Transition>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="رقم الهاتف" v-model="phone" required />
      </div>
      <Transition name="bounce">
        <div class="alert alert-error" role="alert" v-if="errorValidate.errorPhone">
          {{ errorValidate.errorPhone }}
        </div>
      </Transition>
      <div class="form-group">
        <input type="password" class="form-control" placeholder="كلمه السر" v-model="password" required />
      </div>
      <Transition name="bounce">
        <div class="alert alert-error" role="alert" v-if="errorValidate.errorPassword">
          {{ errorValidate.errorPassword }}
        </div>
      </Transition>
      <Transition name="bounce">
        <div class="alert alert-error" role="alert" v-if="errorValidate.errorEmpty">
          {{ errorValidate.errorEmpty }}
        </div>
      </Transition>
    </form>
  </div>
  <div class="col-lg-18 col-xl-18" v-if="dataCart.length > 0">
    <div class="card-total">
      <div class="row d-flex mt-3">
        <div class="col card-total-txt">المجموع</div>
        <div class="col-auto card-total-price text-right">{{ totalPriceBook }} ريال</div>
      </div>
      <div class="wrap-button">
        <button @click="DO_CHECKOUT({ name, email, phone, password })" type="button" class="btn btn--full btn--lg">
          <span>الدفع</span>
        </button>
      </div>
    </div>
    <div class="mt-2"></div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Form",
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      password: null,
    };
  },
  computed: {
    ...mapState("Cart", ["errorValidate", "dataCart", "totalPriceBook"]),
    ...mapState("Auth", ["userID"]),
  },
  methods: {
    ...mapActions("Cart", ["DO_CHECKOUT"]),
  },
};
</script>

<style lang="scss">
.wrap-button {
  display: grid;
  place-items: center;
  button {
    width: 30%;
  }
}
</style>
