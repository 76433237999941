<template>
  <div class="loader-wrapper">
    <div class="loder-crcil"></div>
    <div class="loder-crcil new-loader"></div>
    <div class="loader-statice"></div>
    <div class="loader-statice new-statice"></div>
    <img class="text" src="../../../public/images/logo.svg" />
    <h2>متجر قياس للحقائب التعليمية</h2>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style lang="scss">
// loader
.loader-wrapper {
  background: #fff;
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 120%;
  z-index: 9999;
  display: grid;
  place-items: center;
  h2 {
    position: absolute;
    top: 68%;
    font-size: 30px;
    font-weight: bold !important;
    color: #ffc107;
  }
  .loader-statice {
    width: 250px;
    height: 250px;
    box-shadow: 0 0 2px #afaaaa;
    border-radius: 50%;
    position: absolute;
  }

  .loder-crcil {
    box-shadow: 3px 0 0px #000;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    animation: loader 1s linear infinite;
    position: absolute;
  }
  .new-loader {
    width: 200px;
    height: 200px;
    animation: loader 1s linear infinite reverse;
  }
  .new-statice {
    box-shadow: 0 0 2px #afaaaa;
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .text {
    width: 150px;
    height: 150px;
    // border-radius: 50%;
  }
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(360deg);
  }
}
</style>
