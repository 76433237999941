import axios from "axios";
export default {
  namespaced: true,
  state: {
    books: [],
    categories: [],
    book: [],
    userId: null,
    dataBook: {},
    bookNameRegister: [],
  },
  getters: {},
  mutations: {
    getUserID(state, id) {
      state.userId = id;
    },
    getIsActive(state, { ele, cartIDLS, value }) {
      let storeBooks = [];
      if (ele === "books") {
        state.books.map((ele) => {
          cartIDLS.map((el) => {
            if (parseInt(el) === parseInt(ele.id)) {
              ele.isActive = value;
              storeBooks.push(ele);
            }
          });
          if (!ele.isActive) {
            storeBooks.push(ele);
          }
        });
        state.books = storeBooks;
      } else {
        state.categories.map((ele) => {
          cartIDLS.map((el) => {
            if (parseInt(el) === parseInt(ele.id)) {
              ele.isActive = value;
              storeBooks.push(ele);
            }
          });
          if (!ele.isActive) {
            storeBooks.push(ele);
          }
        });
        state.categories = storeBooks;
      }
    },
    //  get books from api
    getBooks(state, payload) {
      payload.data.map((ele) => {
        payload.cartIDLS.map((el) => {
          if (parseInt(el) === parseInt(ele.id)) {
            ele.isActive = true;
            state.books.push(ele);
          }
        });
        if (!ele.isActive) {
          state.books.push(ele);
        }
      });
    },
    //  get Categories from api
    getCategories(state, payload) {
      state.categories = [];
      payload.data.map((ele) => {
        payload.cartIDLS.map((el) => {
          if (parseInt(el) === parseInt(ele.id)) {
            ele.isActive = true;
            state.categories.push(ele);
          }
        });
        if (!ele.isActive) {
          state.categories.push(ele);
        }
      });
    },

    //  get book from api
    getBook(state, payload) {
      this.commit("Books/clearDataBook");
      state.book = payload.data;
      state.userId = payload.id;
      if (payload.cartLS) {
        payload.cartLS.map((ele, i) => {
          if (parseInt(ele.id) === parseInt(payload.id)) {
            state.dataBook = ele;
          }
        });
      }
    },
    getDataBook(state, { dataBookFromClick }) {
      state.dataBook = dataBookFromClick;
    },
    clearDataBook(state) {
      state.dataBook = {};
    },
    // get book name in page register
    getBookNameRegister(state, payload) {
      state.bookNameRegister = payload;
    },
  },
  actions: {
    // get books from api
    async GET_BOOKS({ commit, state, rootState }) {
      if (!state.books.length > 0) {
        return await axios.get(`/books`).then((res) => {
          commit("getBooks", { data: res.data.books, cartIDLS: rootState.Cart.cartIDLS });
        });
      }
    },
    // get Categories from api
    async GET_CATEGORIES({ commit, state, rootState }) {
      if (!state.categories.length > 0) {
        return await axios.get(`/books/category/2`).then((res) => {
          commit("getCategories", { data: res.data.books, cartIDLS: rootState.Cart.cartIDLS });
        });
      }
    },
    // get book from api
    async GET_BOOK({ commit, rootState, dispatch }, payload) {
      // await dispatch("Cart/GET_CATEGORIES_FROM_LS", null, { root: true });

      if (rootState.Cart.cartLS.length > 0) {
        await axios.get(`/book/${payload}`).then((res) => {
          commit("getBook", { data: res.data.books, cartLS: rootState.Cart.cartLS, id: payload });
        });
      } else {
        await axios.get(`/book/${payload}`).then((res) => {
          commit("getBook", { data: res.data.books, id: payload });
        });
      }
    },
    GET_IS_ACTIVE({ commit, rootState }, { ele }) {
      commit("getIsActive", { ele, cartIDLS: rootState.Cart.cartIDLS, value: true });
    },
    async GET_DATA_BOOK({ commit, state }, { dataBookFromClick }) {
      state.dataBook = {};
      commit("getDataBook", { dataBookFromClick });
    },
    // get book name in page register
    async GET_BOOK_NAME_REGISTER({ commit }) {
      return await axios.get(`/register-books`).then((res) => {
        commit("getBookNameRegister", res.data.books);
      });
    },
  },
};
