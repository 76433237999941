<template>
  <section class="account-wishlist">
    <!-- LottiePlayer -->
    <Lottie />
    <div class="holder breadcrumbs-wrap mt-0">
      <div class="container">
        <ul class="breadcrumbs">
          <li><router-link :to="{ name: 'Home' }">الحقائب التعليمية</router-link></li>
          <li><span>ملزمتي</span></li>
        </ul>
      </div>
    </div>
    <books />
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import store from "../store/index";
import Lottie from "@/components/MyBooks/Lottie.vue";
import Books from "@/components/MyBooks/Books.vue";
export default {
  name: "MyBooks",
  components: { Lottie, Books },
  computed: {
    ...mapState("Auth", ["userID", "user"]),
  },
  async mounted() {
    await this.setUserDataOnReload();
    await this.checkUser();
  },
  methods: {
    ...mapMutations("Auth", ["setUserDataOnReload"]),
    ...mapMutations("Global", ["redirectTo"]),
    ...mapActions("MyBooks", ["GET_MY_BOOKS"]),
    checkUser() {
      // && this.MyBooks > 0
      if (this.userID) {
        this.GET_MY_BOOKS();
        let route = this.$router.currentRoute._value;
        if (route.query.success == "true") {
          store.dispatch("Cart/REMOVE_LS", false);
          document.querySelector(".success-pay").style.display = "block";
          setTimeout(() => {
            document.querySelector(".success-pay").style.display = "none";
          }, 4000);
        } else if (route.query.success == "false") {
          document.querySelector(".error-pay").style.display = "block";
          setTimeout(() => {
            document.querySelector(".error-pay").style.display = "none";
          }, 4000);
        }
      } else {
        this.redirectTo("Home");
      }
    },
  },
};
</script>

<style lang="scss">
.account-wishlist {
  .prd:not(.prd--action-off) .prd-action {
    transform: translateY(-43px);
  }
  .my-cart-account {
    height: 450px;
  }
}

form.button-form {
  .button-loader {
    width: 25px !important;
    height: 25px !important;
    top: 8px !important;
    left: 47% !important;
  }

  &.active {
    .button-loader {
      display: block !important;
      opacity: 1 !important;
    }
    .btn {
      background: #e3e3e3 !important;
      cursor: no-drop;
      &:hover {
        background: #e3e3e3 !important;
      }
    }
  }
}
.empty-title {
  text-align: center;
  margin-top: 40px;
}
</style>
