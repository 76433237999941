<template>
  <div class="parent-cart">
    <div class="holder">
      <div class="container">
        <div class="row">
          <div class="page-title w-100 text-center">
            <h1 class="">عربة التسوق</h1>
          </div>
          <div class="col-lg-18 col-xl-18">
            <div v-if="matchMedia" class="cart-table-prd cart-table-prd--head py-1 d-md-flex" :class="{ 'w-100': userID }">
              <div class="cart-table-prd-image text-center">صورة</div>
              <div class="cart-table-prd-content-wrap my-wrap">
                <div class="table-name">الاسم</div>
                <div class="table-qty">الكمية</div>
                <div class="table-price">سعر</div>
                <div class="table-action">&nbsp;</div>
              </div>
            </div>
            <div class="row my-cart">
              <!-- Products -->
              <Products />
              <!-- Form -->
              <Form />
            </div>
            <!-- Empty -->
            <Empty />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Global/Footer.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import axios from "axios";
import Products from "@/components/Cart/Products.vue";
import Form from "@/components/Cart/Form.vue";
import Empty from "@/components/Cart/Empty.vue";

export default {
  name: "CartView",
  // components: {},
  data() {
    return {
      matchMedia: true,
    };
  },
  components: {
    Footer,
    Products,
    Form,
    Empty,
  },
  computed: {
    ...mapState("Cart", ["lengthDLS"]),
    ...mapState("Auth", ["userID"]),
  },
  async mounted() {
    await this.checkUser();
    this.matchMediaFun();
    setTimeout(() => {
      this.CALC_TOTAL();
    }, 500);
  },
  methods: {
    ...mapMutations("Global", ["redirectTo"]),
    ...mapActions("Cart", ["CALC_TOTAL"]),
    matchMediaFun() {
      this.matchMedia = !window.matchMedia("(max-width: 768px)").matches;
    },
    checkUser() {
      this.lengthDLS > 0 ? "" : this.redirectTo("Home");
    },
  },
};
</script>

<style lang="scss">
.parent-cart {
  .parent-cart {
    .cart-table-prd-info {
      padding-right: 100px;
    }
    .cart-table-prd.cart-table-prd--head {
      width: 73%;
    }
    .cart-table-prd-qty {
      padding-right: 0 !important;
    }
    .cart-table-prd-content-wrap {
      justify-content: space-around;
    }
    .price-group {
      flex-direction: column !important;
      .price-new {
        width: 130px;
        margin-top: 10px;
      }
    }
    .remove-button {
      cursor: pointer;
    }
    .page-title {
      padding: 20px;
    }
    .my-form {
      padding: 10px;
    }
    .cart-table-prd.w-100 {
      .my-wrap {
        > div {
          padding: 0;
        }
        .table-name {
          margin-right: 21px;
        }
        .table-qty {
          margin-right: 140px;
        }
        .table-price {
          margin-right: 32px;
        }
      }
    }
    .my-wrap {
      > div {
        padding: 0;
      }
      .table-name {
        margin-right: -15px;
      }
      .table-qty {
        margin-right: 55px;
      }
      .table-price {
        margin-right: -46px;
      }
    }
  }
}
</style>
