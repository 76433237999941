<template>
  <section class="con-swiper-1">
    <swiper
      v-if="imageSliders.length > 0"
      class="mySwiper-1"
      :loop="true"
      :autoplay="imageSliders.length > 1 ? true : false"
      :allowSlideNext="imageSliders.length > 1 ? true : false"
      :allowSlidePrev="imageSliders.length > 1 ? true : false"
      :pagination="true"
      :modules="modules_1"
    >
      <swiper-slide :data-swiper-autoplay="5000" class="first-slide" v-for="(image, i) in imageSliders" :key="i">
        <img :src="image.image" alt="" />
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Autoplay, EffectCards } from "swiper";
export default {
  name: "Swiper_1",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapState("Cart", ["imageSliders"]),
  },
  async mounted() {
    await this.GET_IMG_SLIDERS();
  },
  data() {
    return {
      modules_1: [Navigation, Autoplay, EffectCards],
    };
  },
  methods: {
    ...mapActions("Cart", ["GET_IMG_SLIDERS"]),
  },
};
</script>

<style lang="scss">
.mySwiper-1 {
  // max-height: 500px;
  max-height: 700px !important;
}
</style>
