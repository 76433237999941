<template>
  <section class="rates">
    <div class="title-section">
      <h2 class="h1-title single-line">آراء العملاء</h2>
      <p>تقييمات و آراء ونجاحات عملائنا مع ملازم و كتب متجر قياس</p>
    </div>
    <div class="customer-reviews" v-if="rates">
      <div v-for="rate in rates" :key="rate.id">
        <div class="content-bottom">
          <img :src="rate.user_image" alt="" />
          <p v-text="rate.name"></p>
          <div class="prd-rating justify-content-center">
            <img :src="rate.rates" alt="" />
          </div>
          <p v-text="rate.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Rates",
  computed: {
    ...mapState("Rates", ["rates"]),
  },
  async mounted() {
    await this.GET_RATES();
    await this.customerReviewsSlide();
  },
  methods: {
    ...mapActions("Rates", ["GET_RATES"]),
    customerReviewsSlide() {
      $(document).ready(function () {
        $(".customer-reviews").slick({
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
          arrows: false,
          adaptiveHeight: true,
          rtl: true,

          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
                dots: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
              },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
          ],
        });
      });
    },
  },
};
</script>

<style lang="scss"></style>
