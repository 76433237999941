<template>
  <div class="parent-login">
    <div class="holder holder-login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-18 col-lg-12">
            <h2 class="text-center mt-3">تسجيل الدخول إلى حساب</h2>
            <div class="form-wrapper">
              <form action="#" @submit.prevent="VALIDATE_LOGIN({ email, password })">
                <div class="flex-group">
                  <div class="form-group shadow-redis-input">
                    <input type="email" class="form-control" placeholder="البريد الإلكتروني" v-model="email" required />
                  </div>

                  <div class="form-group shadow-redis-input">
                    <input type="password" class="form-control" placeholder="كلمه السر" v-model="password" required />
                  </div>
                </div>
                <div class="flex-group">
                  <Transition name="bounce">
                    <div class="alert alert-error" role="alert" v-show="errorValidate.errorEmail">
                      {{ errorValidate.errorEmail }}
                    </div>
                  </Transition>
                  <Transition name="bounce">
                    <div class="alert alert-error" role="alert" v-if="errorValidate.errorPassEmail">
                      {{ errorValidate.errorPassEmail }}
                    </div>
                  </Transition>
                  <Transition name="bounce">
                    <div class="alert alert-error" role="alert" v-if="errorValidate.errorPassword">
                      {{ errorValidate.errorPassword }}
                    </div>
                  </Transition>
                </div>
                <div class="flex-group">
                  <div class="text-center">
                    <button class="btn">تسجيل الدخول</button>
                  </div>
                  <div class="text-center">
                    <router-link class="btn" to="/register">إنشاء حساب جديد</router-link>
                  </div>
                </div>
                <Transition name="slide-fade">
                  <p class="success-login" v-if="message" v-text="message"></p>
                </Transition>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: null,
    };
  },
  computed: {
    ...mapState("Auth", ["message", "errorValidate"]),
  },
  methods: {
    ...mapActions("Auth", ["VALIDATE_LOGIN"]),
  },
};
</script>

<style lang="scss" scoped></style>
