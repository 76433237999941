<template>
  <nav class="header-top">
    <Header />
  </nav>
  <Loader />
  <div class="page-content">
    <Social />
    <router-view></router-view>
  </div>
  <Footer />
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Header from "@/components/Global/Header.vue";
import Footer from "@/components/Global/Footer.vue";
import Social from "@/components/Global/Social.vue";
import Loader from "@/components/Global/Loader.vue";
import router from "./router/index";
export default {
  name: "AppComp",
  components: {
    Header,
    Footer,
    Social,
    Loader,
  },

  async mounted() {
    await this.GET_CATEGORIES_FROM_LS();
    await this.setUserDataOnReload();
    await this.LENGTH_DLS();
    await this.MENU_HEADER();
    await this.hideLoader();
    await this.HANDEL_CLICK_ADD_TO_CART(document.querySelectorAll(".handel-click.active"));
  },
  // has-sticky
  methods: {
    ...mapMutations("Auth", ["setUserDataOnReload"]),
    ...mapActions("Cart", ["LENGTH_DLS", "GET_CATEGORIES_FROM_LS", "CALC_TOTAL"]),
    ...mapMutations("Global", ["addElement"]),
    ...mapActions("Global", ["HANDEL_CLICK_ADD_TO_CART", "MENU_HEADER"]),

    hideLoader() {
      $(document).ready(function () {
        if (router.currentRoute._value.name != "Home" && router.currentRoute._value.name != "Book") {
          let loader = $(".loader-wrapper");
          loader.fadeOut(1000);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Cairo", sans-serif !important;
  font-weight: 500 !important;
}
.page-content {
  .alert-error {
    color: #721c24 !important;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  .bounce-enter-active {
    animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
  }
  .slide-fade-enter-active {
    transition: all 0.6s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
  .success-login {
    text-align: center;
    font-weight: bold !important;
    background: #49d8d985;
    border-radius: 5px;
    padding: 15px;
    color: #383838;
  }
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
