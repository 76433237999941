import axios from "axios";
export default {
  namespaced: true,
  state: {
    imageSliders: [],
    cartIDLS: [],
    cartLS: [],
    dataCart: [],
    lengthDLS: 0,
    totalPriceBook: 0,
    errorValidate: {},
  },
  mutations: {
    // Validate Values Input
    validateCheckout(state, {name, email, phone, password}) {
      if (name || email || phone || password) {
        state.errorValidate = {};
        let phoneUser = phone,
          regPhone = /05\d{8}/,
          emailUser = email,
          regEmail = /\w+@\w+[.]\w+/gi,
          passwordUser = String(password),
          regPassword = /.{8,}/gi;
        if (!regPhone.test(phoneUser) || phone.length > 10) {
          state.errorValidate.errorPhone = "من فضلك يجب ان يبدا رقم الهاتف ب 05 ويتكون من 8 ارقام";
        }
        if (!regEmail.test(emailUser)) {
          state.errorValidate.errorEmail = "ادخل بريد الكتروني صحيح";
        }
        if (!regPassword.test(passwordUser)) {
          state.errorValidate.errorPassword = "يجب ان تكون كلمة السر اكبر من 6 ارقام علا الاقل";
        }
        if (Object.keys(state.errorValidate).length > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        state.errorValidate.errorEmpty = "الحقول فارغة";
      }
    },
    //  get Slider in book page
    getImgSliders(state, payload) {
      state.imageSliders = payload;
    },
    // get length data from localStorage
    lengthDLSFun(state) {
      if (localStorage.getItem("cartId")) {
        state.lengthDLS = JSON.parse(localStorage.getItem("cartId")).length;
      }
    },
    // Set Book Id to LocalStorage and Qty
    setCartIdToLS(state, payload) {
      let cartsID = JSON.parse(localStorage.getItem("cartId")),
        duplicate = null;
      if (cartsID) {
        [duplicate] = cartsID.filter((ele, i) => ele.id === payload.id);
        if (duplicate) {
          cartsID.map((ele, i) => {
            if (ele.id === duplicate.id) {
              cartsID[i] = {id: payload.id, qty: payload.qty};
            }
          });
        } else {
          cartsID.push({id: payload.id, qty: payload.qty});
        }
      } else {
        cartsID = [{id: payload.id, qty: payload.qty}];
      }
      localStorage.setItem("cartId", JSON.stringify(cartsID));
    },
    // get Total Price
    calcTotal(state) {
      state.dataCart.map((ele_1) => {
        state.cartLS.map((ele_2) => {
          if (parseInt(ele_1.id) === parseInt(ele_2.id)) {
            state.totalPriceBook += ele_2.qty * ele_1.price;
          }
        });
      });
    },
    // decrease and increase Qty in book page and LS
    IDQty(state, payload) {
      let cartsID = JSON.parse(localStorage.getItem("cartId"));
      if (cartsID) {
        cartsID.map((ele, i) => {
          if (parseInt(ele.id) === parseInt(payload.id)) {
            let qty = ele.qty;
            if (payload.type == "decrease" && ele.qty > 1) {
              qty--;
            }
            if (payload.type == "increase" && ele.qty > 0 && ele.qty < 50) {
              qty++;
            }
            cartsID[i] = {id: payload.id, qty: qty};
            this.commit("Books/getDataBook", {dataBookFromClick: {id: payload.id, qty: qty}});
            state.cartLS = cartsID;
            localStorage.setItem("cartId", JSON.stringify(cartsID));
          }
        });
      }
    },
    // remove Item from LocalStorage
    removeItemLS(state, id) {
      let cartsID = JSON.parse(localStorage.getItem("cartId"));
      if (cartsID) {
        cartsID = cartsID.filter((ele) => parseInt(ele.id) !== parseInt(id));
      } else {
        console.log("Not Found Item");
      }
      localStorage.setItem("cartId", JSON.stringify(cartsID));
    },
    // remove LocalStorage
    removeLS(state) {
      localStorage.removeItem("cartId");
      state.dataCart = [];
      state.cartLS = [];
      state.cartIDLS = [];
      state.lengthDLS = 0;
    },
  },
  actions: {
    //  get Slider in book page
    async GET_IMG_SLIDERS({commit, state}) {
      if (!state.imageSliders.length > 0) {
        return await axios.get(`/sliders`).then((res) => {
          commit("getImgSliders", res.data.data);
        });
      }
    },
    // get length data from localStorage
    LENGTH_DLS({commit}) {
      commit("lengthDLSFun");
    },
    // Set Book Id to LocalStorage and Qty
    async SET_CART_ID_TO_LS({commit, dispatch}, payload) {
      await commit("setCartIdToLS", {id: parseInt(payload.id), qty: parseInt(payload.qty)});
      await dispatch("GET_CATEGORIES_FROM_LS");
      // await dispatch("CALC_TOTAL");
      await commit("lengthDLSFun");
    },
    // get Total Price
    CALC_TOTAL({commit, state}) {
      state.totalPriceBook = 0;
      return commit("calcTotal");
    },
    // Get id book select from LS and get Book selected form user from api
    async GET_CATEGORIES_FROM_LS({commit, state, dispatch, rootState}) {
      let cartsID = await JSON.parse(localStorage.getItem("cartId"));
      // state.cartLS = cartsID;
      if (cartsID) {
        state.cartIDLS = [];
        await cartsID.map((ele) => {
          state.cartIDLS.push(parseInt(ele.id));
        });
        state.cartIDLS = [...new Set(state.cartIDLS)];
        await axios
          .post(`/cart`, {
            items: state.cartIDLS,
          })
          .then((res) => {
            state.dataCart = [];
            state.dataCart = res.data.books;
            state.cartLS = [];
            cartsID.map((ele) => {
              state.cartLS.push({id: parseInt(ele.id), qty: parseInt(ele.qty)});
            });
            if (state.cartIDLS.length >= 0) {
              // dispatch("Books/GET_DATA_BOOK", {id:  cartIDLS: state.cartIDLS }, { root: true });
            }
          });
      }
      await dispatch("CALC_TOTAL");
      await commit("lengthDLSFun");
    },

    // decrease and increase Qty in book page and LS
    ID_QTY({commit, dispatch}, payload) {
      commit("IDQty", payload);
      dispatch("GET_CATEGORIES_FROM_LS");
    },
    // remove Item from LocalStorage
    async REMOVE_ITEM_LS({commit, dispatch, rootState}, id) {
      await commit("removeItemLS", id);
      await dispatch("GET_CATEGORIES_FROM_LS");
      commit("lengthDLSFun");
    },
    // remove LocalStorage
    async REMOVE_LS({commit, state, rootState}, value) {
      await commit("removeLS");
      await commit("calcTotal");
      await commit("Books/getIsActive", {ele: "categories", cartIDLS: state.cartIDLS, value: false}, {root: true});
      await commit("Books/clearDataBook", null, {root: true});
      if (value) {
        await commit("Global/redirectTo", "Home", {root: true});
      }
    },
    // register and checkout
    // txn_response_code=APPROVED success=true
    async DO_CHECKOUT({state, commit, rootState}, {name, email, phone, password}) {
      if (rootState.Auth.userID) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${rootState.Auth.user.user.token}`;
        axios
          .post("/checkout", {
            user_id: rootState.Auth.userID,
            cartItems: state.cartLS,
            total: state.totalPriceBook,
          })
          .then((res) => {
            if (res.data.status) {
              window.location.href = res.data.payment_page;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await commit("validateCheckout", {name, email, phone, password});
        if (!Object.keys(state.errorValidate).length > 0) {
          axios
            .post("/register/checkout", {name, email, phone, password})
            .then((res) => {
              if (res.data.status) {
                axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.user.token}`;
                commit("Auth/setUserData", res.data, {root: true});
                axios
                  .post("/checkout", {
                    cartItems: state.cartLS,
                    total: state.totalPriceBook,
                  })
                  .then((res) => {
                    if (res.data.status) {
                      window.location.href = res.data.payment_page;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("Not Validate");
        }
      }
    },
  },
};
