<template>
  <div class="col-md-10 col-lg-8 mt-1 mt-md-0">
    <div class="prd-block_info prd-block_info--style1" data-prd-handle="/products/copy-of-suede-leather-mini-skirt">
      <div class="prd-block_info-top prd-block_info_item order-0 order-md-2">
        <div class="prd-block_price prd-block_price--style2">
          <div class="prd-block_price--actual">{{ book.price }} ريال</div>
          <div class="prd-block_price-old-wrap">
            <span class="prd-block_price--old">{{ book.discount }} ريال</span>
            <span class="prd-block_price--text">توفير: {{ book.discount - book.price }} ريال</span>
          </div>
        </div>
      </div>
      <div class="prd-block_description prd-block_info_item">
        <!-- <h3>وصف قصير</h3> -->
        <p>
          {{ book.description }}
        </p>
        <div class="mt-1"></div>
      </div>
      <div class="prd-block_description prd-block_info_item purchase-count">
        <h3>تم شراءه</h3>
        <p>
          {{ book.purchase_count }}
          مرة
        </p>
        <div class="mt-1"></div>
      </div>
      <div class="order-0 order-md-100">
        <form method="post" action="#">
          <div class="prd-block_actions prd-block_actions--wishlist">
            <div class="btn-wrap">
              <div class="prd-block_actions prd-block_actions--wishlist">
                <div class="prd-block_qty" v-if="dataBook.id">
                  <div class="qty qty-changer">
                    <button class="decrease js-qty-button" @click.prevent="ID_QTY({ type: 'decrease', id })"></button>
                    <input
                      :value="dataBook.qty ? dataBook.qty : 1"
                      disabled
                      lang="en"
                      type="number"
                      class="qty-input"
                      name="quantity"
                      min="1"
                      max="5"
                    />
                    <button @click.prevent="ID_QTY({ type: 'increase', id })" class="increase js-qty-button"></button>
                  </div>
                  <div>{{ dataBook.qty ? dataBook.qty * book.price : book.price }} ريال</div>
                </div>
                <div class="btn-wrap" v-if="modeSucceeded">
                  <button
                    @click="(event) => addClassActive(event)"
                    @click.prevent.stop="
                      SET_CART_ID_TO_LS({ id: book.id, qty: dataBook.qty ? dataBook.qty : 1 });
                      animateAddToCart(book.image, dataBook.qty ? dataBook.qty : 1);
                    "
                    class="btn btn--add-to-cart js-prd-addtocart handel-click"
                    :class="{ active: dataBook.id != null }"
                  >
                    <!-- إضافة للسلة  -->
                    <i class="fa fa-cart-plus"></i>
                    {{ dataBook.id ? "تم اضافتة الي السلة" : "إضافة للسلة " }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Info",
  props: ["book", "id"],
  computed: {
    ...mapState("MyBooks", ["modeSucceeded"]),
    ...mapState("Books", ["dataBook"]),
  },

  async mounted() {
    await this.handelClickAddToCart(document.querySelectorAll("button.active"));
  },
  methods: {
    ...mapActions("Cart", ["ID_QTY", "SET_CART_ID_TO_LS"]),
    ...mapActions("Books", ["GET_DATA_BOOK"]),
    animateAddToCart(srcImage, qty) {
      this.GET_DATA_BOOK({ dataBookFromClick: { id: this.id, qty } });
      this.handelClickAddToCart(document.querySelectorAll(".handel-click.active"));
      let imgNew = document.createElement("img");
      imgNew.src = srcImage;
      imgNew.classList.add("animate-img");
      document.body.appendChild(imgNew);
      let cartAnimated = document.querySelector(".icon-basket");
      cartAnimated.classList.add("active");
      setTimeout(() => {
        imgNew.remove();
        setTimeout(() => {
          cartAnimated.classList.remove("active");
        }, 800);
      }, 1000);
    },
    handelClickAddToCart(eleButton) {
      if (this.isLoading) {
        eleButton.forEach((ele) => {
          ele.setAttribute("disabled", true);
          ele.innerText = "تم اضافتة الي السلة";
        });
      }
    },
    addClassActive(event) {
      event.target.classList.add("active");
    },
  },
};
</script>

<style lang="scss">
.purchase-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    flex: 1 0 50%;
    font-size: 25px;
    font-weight: bold !important;
    color: #49dee7;
  }
  p {
    font-size: 25px;
    font-weight: bold !important;
    color: #49dee7;
  }
}
.prd-block_actions {
  display: block;
  .btn-wrap {
    flex: 0 1;
    margin: auto;
    width: 300px;
    display: block;
  }
}
</style>
