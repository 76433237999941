<template>
  <div class="success-pay">
    <h1 class="success-title">تم عملية الشراء بنجاح</h1>
    <lottie-player
      class="lottie-player"
      src="https://assets10.lottiefiles.com/packages/lf20_jbrw3hcz.json"
      background="transparent"
      speed="1"
      loop
      autoplay
    ></lottie-player>
    <lottie-player
      src="https://assets10.lottiefiles.com/packages/lf20_lg6lh7fp.json"
      background="transparent"
      speed="1"
      class="lottie-player-success"
      loop
      autoplay
    ></lottie-player>
  </div>
  <div class="error-pay">
    <h1 class="error-title">حدث خطاء في عملية الشراء</h1>
    <lottie-player
      src="https://assets1.lottiefiles.com/packages/lf20_qw8ewk7k.json"
      background="transparent"
      speed="1"
      class="lottie-player-error"
      loop
      autoplay
    ></lottie-player>
  </div>
</template>
<script>
// import "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js";
export default {
  name: "Lottie",
};
</script>

<style lang="scss">
.error-pay,
.success-pay {
  position: fixed;
  z-index: 999;
  display: none;
  width: 100vw;
  height: 100vh;
  background: white;
  top: 0;
}
.success-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 14%;
  font-weight: bold !important;
  color: var(--color-2);
}
.error-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 14%;
  font-weight: bold !important;
  color: red;
}
.lottie-player-success {
  width: 1000px;
  height: 800px;
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.lottie-player-error {
  width: 600px;
  height: 400px;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
</style>
