<template>
  <div class="home">
    <!-- Swiper_1 Home -->
    <Swiper_1 />
    <div class="holder holder-mt-medium">
      <div class="container">
        <!-- Swiper_2 Categories  -->
        <Swiper_2 />
        <!-- Swiper_3 Books  -->
        <!-- <Swiper_3 /> -->
        <!-- Customer Reviews -->
        <Rates />
      </div>
    </div>
  </div>
</template>
<script>
import Swiper_1 from "@/components/Home/Swiper_1.vue";
import Swiper_2 from "@/components/Home/Swiper_2.vue";
import Swiper_3 from "@/components/Home/Swiper_3.vue";
import Rates from "@/components/Home/Rates.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import "swiper/css/pagination";
import "swiper/css/bundle";
export default {
  name: "HomeView",
  components: {
    Swiper_1,
    Swiper_2,
    Swiper_3,
    Rates,
  },

  computed: {},

  async mounted() {
    await this.HIDE_LOADER();
  },
  methods: {
    ...mapActions("Global", ["HIDE_LOADER"]),
  },
};
// hovered
</script>

<style src="@/assets/css/style-books.css"></style>

<style lang="scss">
.home {
  .prd {
    .prd-description {
      display: block !important;
      font-size: 14px;
      margin-top: 10px;
      color: #5c5c5c;
      padding: 10px;
    }
    .prd-title {
      a {
        font-weight: bold !important;
      }
    }
    .prd-price .price-new {
      font-weight: bold !important;
    }
    .discount {
      margin-top: 0;
      margin-right: 30px;
      text-decoration: line-through;
      font-size: 15px;
      color: #565959;
    }
    .form-submit {
      position: relative;
      z-index: 999;
    }
    .shuffle {
      .buttons-group {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 800px;
        margin: auto;
        button {
          background: #49d8d9;
        }
      }
    }
    .purchase-count {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      h3 {
        flex: 1 0 50%;
        font-size: 16px;
        font-weight: bold !important;
        color: #49dee7;
        margin: 0;
      }
      p {
        font-size: 16px;
        font-weight: bold !important;
        color: #ffc107 !important;
        margin: 0;
      }
    }
    span[title="Cut Text"],
    span[title="Show All"] {
      cursor: pointer;
    }
  }
}
</style>
