<template>
  <div class="contact-social">
    <div @click="isOpenSocial" class="social-list-circle-sm social">
      <article class="plus">+ <span class="bg-social"></span></article>
      <div class="shadowSocial">
        <a href="http://wa.me/966506060772" target="_blank"><img src="../../../public/images/social/whatsapp.png" alt="" /></a>
      </div>
      <div class="shadowSocial">
        <a href="https://twitter.com/QiyasCo" target="_blank"><i class="icon-twitter"></i></a>
      </div>
      <div class="shadowSocial">
        <a href="https://t.me/qiyasco" target="_blank"><i class="fa-brands fa-telegram icon"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "Social",
  setup() {
    const isOpenSocial = () => {
      let puls = document.querySelector("article.plus"),
        contactSocial = document.querySelector(".contact-social"),
        bgSocial = document.querySelector(".bg-social");
      if (contactSocial.classList.contains("open")) {
        contactSocial.classList.remove("open");
        puls.classList.remove("active");
        puls.style.transform = "rotate(0deg)";

        setTimeout(() => {
          puls.style.background = "transparent";
          bgSocial.style.cssText = "transform: scale(1)";
          bgSocial.style.cssText = "background-position: 0%;";
          setTimeout(() => {
            puls.style.width = "184px";
          }, 800);
        }, 800);
      } else {
        contactSocial.classList.add("open");
        puls.classList.add("active");
        setTimeout(() => {
          puls.style.cssText = "width: 50px; background: #00c2c4 ; border-radius: 25px;";
          bgSocial.style.cssText = "background-position: -2%;";
          setTimeout(() => {
            bgSocial.style.cssText = "transform: scale(0)";
            setTimeout(() => {
              puls.style.transform = "rotate(-180deg)";
            }, 300);
          }, 800);
        }, 300);
      }
    };
    return { isOpenSocial };
  },
};
</script>

<style lang="scss">
@mixin showSocialMixin($right, $top, $name-1, $name-2) {
  @keyframes #{$name-1} {
    0% {
      opacity: 0.2;
      transform: scale(0.2);
      right: 0;
      top: 0;
    }
    100% {
      opacity: 1;
      transform: scale(1);
      right: $right;
      top: -$top;
    }
  }
  @keyframes #{$name-2} {
    0% {
      opacity: 1;
      transform: scale(1);
      right: $right;
      top: -$top;
    }
    100% {
      opacity: 0.2;
      transform: scale(0.2);
      top: 0px;
      right: 0;
    }
  }
}
@include showSocialMixin(0px, 100px, "icon-1", "icon-01");
@include showSocialMixin(70px, 70px, "icon-2", "icon-02");
@include showSocialMixin(100px, 0px, "icon-3", "icon-03");
$transition: 0.3s;
.contact-social {
  position: fixed;
  z-index: 999;
  right: 12px;

  bottom: 60px;

  > div {
    position: relative;
    // width: 50px;
    // height: 50px;

    article.plus {
      padding: 8px;
      width: 184px;
      height: 50px;
      color: #fff;
      cursor: pointer;
      transition: 1s;
      position: absolute;
      font-size: 50px;
      right: 0;
      &.active {
        background: rgb(0, 194, 196);
        border-radius: 25px;
        box-shadow: 5px 5px 10px rgb(72 72 72 / 19%), -5px -5px 10px #6c6c6c14, inset -5px -5px 10px #ffffffab,
          inset 5px 5px 10px #00000040;
        border: 3px solid rgb(0, 194, 196);
        text-indent: 1px;
        line-height: 0.4;
        &:active {
          box-shadow: inset -5px -5px 10px #00000026, inset 5px 5px 10px #00000026;
        }
      }
      .bg-social {
        background-image: url("/public/images/social/bg-social.gif");
        width: 100%;
        border-radius: 25px;
        height: 100%;
        background-size: 200px !important;
        background-repeat: no-repeat !important;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        transition: 1s;
        &::after {
          content: "";
          position: absolute;
        }
      }
    }
    > div {
      transition: all 0.3s;
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      transform: scale(0.5);
      z-index: -1;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-of-type(1) {
        animation: icon-01 $transition linear both;
      }
      &:nth-of-type(2) {
        // background: #fff;
        animation: icon-02 $transition 0.3s linear both;
      }
      &:nth-of-type(3) {
        // background: #4cc75a;
        animation: icon-03 $transition 0.6s linear both;
      }
    }
  }
  &.open {
    > div > div {
      &:nth-of-type(1) {
        animation: icon-1 $transition 1.4s linear both;
      }
      &:nth-of-type(2) {
        animation: icon-2 $transition 1.7s linear both;
      }
      &:nth-of-type(3) {
        animation: icon-3 $transition 1.9s linear both;
      }
    }
  }
}
</style>
