<template>
  <div class="cart-table col-13" v-if="dataCart.length > 0" :class="{ 'col-18': userID }">
    <div class="cart-table-prd" v-for="(book, i) in dataCart" :key="book.id" :id="book.id">
      <div class="cart-table-prd-image">
        <router-link :to="{ name: 'Book', params: { id: book.id } }" class="prd-img"
          ><img class="lazyload fade-up" :src="book.image"
        /></router-link>
      </div>
      <div class="cart-table-prd-content-wrap">
        <div class="">
          <h2 class="cart-table-prd-name">
            <a href="#">{{ book.name }}</a>
          </h2>
        </div>
        <div class="cart-table-prd-qty">
          <div class="qty qty-changer">
            <button class="decrease" @click.prevent="ID_QTY({ type: `decrease`, id: parseInt(cartLS[i].id) })"></button>
            <input type="text" class="qty-input" :value="cartLS[i].qty" data-min="0" data-max="1000" />
            <button class="increase" @click.prevent="ID_QTY({ type: `increase`, id: parseInt(cartLS[i].id) })"></button>
          </div>
          <div class="cart-table-prd-price-total">{{ cartLS[i].qty * book.price }} ريال</div>
        </div>
        <div class="cart-table-prd-price price-group price-discount">
          <div class="price-old">{{ book.discount }} ريال</div>
          <div class="price-new">{{ book.price }} ريال</div>
        </div>
      </div>
      <!-- ~~ -->
      <div class="cart-table-prd-action remove-button" @click="() => REMOVE_ITEM_LS(book.id)">
        <span class="cart-table-prd-remove" data-tooltip="Remove Product"><i class="icon-recycle"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Products",
  computed: {
    ...mapState("Cart", ["dataCart", "cartLS"]),
    ...mapState("Auth", ["userID"]),
  },
  methods: {
    ...mapActions("Cart", ["ID_QTY", "REMOVE_ITEM_LS"]),
  },
};
</script>

<style></style>
