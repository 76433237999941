<template>
  <div class="text-center mt-1">
    <a href="#" class="btn btn--grey mb-3" @click="REMOVE_LS(true)" v-if="dataCart.length > 0">امسح الكل</a>
  </div>
  <div class="minicart-empty js-minicart-empty" v-if="lengthDLS < 1">
    <div class="minicart-empty-text">عربة التسوق فارغة</div>
    <div class="minicart-empty-icon">
      <i class="icon-shopping-bag"></i>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 306 262"
        style="enable-background: new 0 0 306 262"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M78.1,59.5c0,0-37.3,22-26.7,85s59.7,237,142.7,283s193,56,313-84s21-206-69-240s-249.4-67-309-60C94.6,47.6,78.1,59.5,78.1,59.5z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Empty",
  computed: {
    ...mapState("Cart", ["dataCart", "lengthDLS"]),
  },
  methods: {
    ...mapActions("Cart", ["REMOVE_LS"]),
  },
};
</script>

<style></style>
