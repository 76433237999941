<template>
  <div class="holder breadcrumbs-wrap mt-0">
    <div class="container">
      <ul class="breadcrumbs">
        <li><router-link :to="{ name: 'Home' }">الحقائب التعليمية</router-link></li>
        <li><span>الحساب</span></li>
      </ul>
    </div>
  </div>
  <div class="container my-account" v-if="userID">
    <main class="aside-account">
      <div class="aside aside--left" style="margin-top: 10px">
        <div class="list-group list-group-button">
          <router-link :to="{ name: 'MyBooks' }">
            <button data-class="obligation" class="list-group-item">ملزمتي</button>
          </router-link>
          <router-link :to="{ name: 'Account' }">
            <button data-class="my-account" class="list-group-item active">الحساب</button>
          </router-link>
        </div>
      </div>
    </main>
    <div class="vert-margin mt-1 form-info">
      <h1 class="mb-3 mt-1">بيانات المستخدم</h1>
      <div class="mt-1">
        <div class="card">
          <div class="card-body">
            <h3>معلومات شخصية</h3>
            <div class="col-6 img-c avatar">
              <img :src="user.user.image" alt="" />
            </div>
            <p>
              <b>الاسم كامل:</b> {{ user.user.name }}<br />
              <b>البريد الإلكتروني:</b> {{ user.user.email }}<br />
              <b>رقم الهاتف:</b> {{ user.user.phone }}
            </p>
            <div class="mt-2 clearfix list-group-button-edit d-none">
              <button data-class="form-info" class="list-group-item active"><i class="icon-pencil"></i>تعديل</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3 hidden form-edit" id="updateDetails">
      <div class="card-body">
        <h3>تحديث بيانات الحساب</h3>
        <div class="flex-group">
          <div class="form-group shadow-redis-input">
            <input type="text" class="form-control" placeholder="الاسم كامل" :value="user.user.name" required />
          </div>
          <div class="form-group shadow-redis-input">
            <input type="text" class="form-control" placeholder="رقم الهاتف" :value="user.user.phone" required />
          </div>
          <!-- <Transition name="bounce">
            <div class="alert alert-error" role="alert" v-if="errorValidate.errorPhone">
              {{ errorValidate.errorPhone }}
            </div>
          </Transition> -->
        </div>
        <div class="flex-group">
          <div class="form-group shadow-redis-input">
            <input type="email" class="form-control" placeholder="البريد الإلكتروني" :value="user.user.email" required />
          </div>
          <!-- <Transition name="bounce">
            <div class="alert alert-error" role="alert" v-show="errorValidate.errorEmail">
              {{ errorValidate.errorEmail }}
            </div>
          </Transition> -->
          <div class="form-group shadow-redis-input">
            <input type="password" class="form-control" placeholder="كلمه السر" value="********" required />
          </div>
          <!-- <Transition name="bounce">
            <div class="alert alert-error" role="alert" v-if="errorValidate.errorPassword">
              {{ errorValidate.errorPassword }}
            </div>
          </Transition> -->
        </div>
        <div class="mt-2">
          <button type="reset" class="btn btn--alt js-close-form" data-form="#updateDetails">Cancel</button>
          <button type="submit" class="btn ml-1">Update</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "Account",
  computed: {
    ...mapState("Auth", ["userID", "user"]),
  },
  async mounted() {
    this.setUserDataOnReload();
    // this.witchTaps();
  },
  methods: {
    ...mapMutations("Auth", ["setUserDataOnReload"]),

    /*  witchTaps() {
      $(function () {
        let buttons = $(".list-group-button-edit button");
        buttons.on("click", function (e) {
          $("." + $(this).attr("data-class")).fadeOut(500);
          $(".form-edit").fadeIn(500);
        });
      });
    }, */
  },
};
</script>

<style lang="scss" scoped>
.my-account {
  max-width: 1200px !important;
  .aside-account {
    width: 266px;
  }
  display: grid;
  grid-template-columns: repeat(2, auto);
  .img-c {
    img {
      border-radius: 50%;
      box-shadow: 0 0 5px 4px #4646462e;
      padding: 4px;
    }
  }
}
</style>
