<template>
  <footer class="page-footer footer-style-2 holder-mt-small mb-50">
    <div class="footer-top">
      <div class="container border-b">
        <div class="row align-start">
          <div class="col-md-6 col-lg">
            <div class="footer-block collapsed-mobile" :class="{ open: isOpen1 }">
              <div class="title">
                <div class="col-sm footer-bottom-col-left">
                  <div class="footer-logo">
                    <router-link :to="{ name: 'Home' }" class="logo text-center"
                      ><img src="../../../public/images/logo.png" alt="Logo"
                    /></router-link>
                  </div>
                </div>
                <span class="toggle-arrow" @click="() => (isOpen1 = !isOpen1)"><span></span><span></span></span>
              </div>
              <div class="collapsed-content">
                <div class="footer-txt-block text-center">
                  متجر قياس للحقائب التعليمية ملازم وحقائب تعليمية مصممة حسب المعايير الجديدة لهيئة التقويم والتدريب بالتعاون مع
                  نخبة من الدكاترة الخبراء المعتمدين المتخصصين
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 order-2">
            <div class="col-sm-auto reset-center">
              <p class="footer-copy text-center">جميع الحقوق محفوظة &copy; لدى متجر قياس <span></span></p>
            </div>
            <div class="pay">
              <img src="../../../public/images/pay/01.png" />
              <img src="../../../public/images/pay/02.png" alt="" />
              <img src="../../../public/images/pay/03.png" alt="" />
              <img src="../../../public/images/pay/04.png" alt="" />
              <!-- <img src="../../../public/images/pay/05.svg" alt="" />     -->
            </div>
          </div>
          <div class="col-md-6 col-lg">
            <div class="footer-block collapsed-mobile" :class="{ open: isOpen2 }">
              <div class="title">
                <h4 class="text-align text-center">للتواصل و الإستفسار</h4>
                <span class="toggle-arrow" @click="() => (isOpen2 = !isOpen2)"><span></span><span></span></span>
              </div>
              <div class="collapsed-content">
                <ul class="social-list-circle-sm social">
                  <li>
                    <a class="shadowSocial" href="http://wa.me/966506060772" target="_blank"
                      ><img src="../../../public/images/social/whatsapp.png" alt=""
                    /></a>
                  </li>
                  <li>
                    <a class="shadowSocial" href="https://twitter.com/QiyasCo" target="_blank"><i class="icon-twitter"></i></a>
                  </li>
                  <li>
                    <a class="shadowSocial" href="https://t.me/qiyasco" target="_blank"
                      ><i class="fa-brands fa-telegram icon"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row vert-margin-middle">
          <div class="col-sm footer-bottom-col-right">
            <div class="vert-margin-middle maroof">
              <img src="../../../public/images/social/maroof.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      isOpen1: true,
      isOpen2: true,
    };
  },
  mounted() {
    this.getYearsFooter();
  },
  methods: {
    getYearsFooter() {
      let yearFooter = document.querySelector(".footer-copy span");
      let year = new Date().getFullYear();
      yearFooter.textContent = year;
    },
  },
};
</script>

<style lang="scss">
.social {
  margin-right: auto !important;
  margin-left: auto !important;
}
.footer-bottom-col-left {
  flex: 2 0 32% !important;
  .social-list-circle-sm.social {
    justify-content: space-evenly;
  }
}
.title {
  margin-bottom: 20px;
}
.text-center {
  text-align: center;
}
.footer-copy {
  color: #00c2c4;
  font-weight: bold;
  font-size: 22px;
}
.align-start {
  align-items: start;
}
.page-footer {
  p {
    font-size: 14px !important;
    font-weight: bold !important;
    margin-bottom: 30px !important;
  }
}
.pay {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    width: 60px;
    cursor: pointer;
  }
}
.border-b {
  border-bottom: 2px solid;
  padding-bottom: 20px;
}
.footer-bottom,
.footer-top {
  padding-bottom: 0px !important;
}

.maroof {
  text-align: center;
}
.footer-logo {
  justify-content: center;
}
</style>
