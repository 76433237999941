<template>
  <div class="holder" :class="{ active: this.MyBooks.length == 0 }">
    <div class="container mb-100">
      <div class="row mt-30">
        <div class="col-md-4 aside aside--left" style="margin-top: 10px">
          <div class="list-group list-group-button">
            <router-link :to="{ name: 'MyBooks' }">
              <button data-class="obligation" class="list-group-item active">ملزمتي</button>
            </router-link>
            <router-link :to="{ name: 'Account', params: { id: `${userID}` } }">
              <button data-class="my-account" class="list-group-item">الحساب</button>
            </router-link>
          </div>
        </div>
        <div class="obligation col-md-14 aside">
          <h1 class="mb-3" v-if="MyBooks > 0">ملزمتي</h1>
          <div class="empty-wishlist text-center py-3 py-sm-5" v-if="MyBooks == 0">
            <h1 class="empty-title">لا يوجد كتب تم شرائها</h1>
            <div class="mt-5">
              <router-link :to="{ name: 'Home' }" class="btn">مواصلة التسوق</router-link>
            </div>
          </div>
          <div class="prd-grid-wrap position-relative">
            <div
              class="prd-grid prd-grid--wishlist data-to-show-3 data-to-show-lg-3 data-to-show-md-2 data-to-show-sm-2 data-to-show-xs-1"
              v-if="MyBooks"
            >
              <div
                class="prd prd--in-wishlist prd--style2 prd-labels--max prd-labels-shadow my-books-style"
                v-for="myBook in MyBooks"
                :key="myBook.id"
              >
                <div class="prd-inside my-cart-account">
                  <div class="prd-img-area">
                    <router-link
                      @click="succeeded()"
                      :to="{ name: 'Book', params: { id: myBook.id }, query: { isActive: true } }"
                      class="prd-img image-hover-scale image-container"
                    >
                      <img
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        :data-src="myBook.image"
                        class="js-prd-img lazyload fade-up"
                      />
                      <div class="foxic-loader"></div>
                      <div class="prd-big-squared-labels"></div>
                    </router-link>
                  </div>
                  <div class="prd-info">
                    <div class="prd-info-wrap">
                      <h2 class="prd-title worning-title">
                        لا نحل النشر للحقيبة لشخص أخر أو جهة أخرى
                        <!-- <router-link
                          @click="succeeded()"
                          :to="{ name: 'Book', params: { id: myBook.id }, query: { isActive: true } }"
                          >{{ myBook.meta_title }}</router-link
                        > -->
                      </h2>
                      <!-- <h6>
                          <p href="product.html">{{ myBook.description }}</p>
                        </h6> -->
                    </div>
                    <div class="prd-hovers">
                      <div class="prd-price">
                        <div class="price-new">{{ myBook.price }} ريال</div>
                      </div>
                      <div class="prd-action">
                        <div class="prd-action-left">
                          <form class="button-form" action="#" @submit.prevent="downBook(myBook.id, myBook.name)">
                            <button class="btn">
                              تحميل الكتاب
                              <div class="foxic-loader button-loader"></div>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
export default {
  name: "Books",
  computed: {
    ...mapState("Auth", ["userID", "user"]),
    ...mapState("MyBooks", ["MyBooks"]),
  },
  async mounted() {
    // await this.switchTaps();
  },
  methods: {
    ...mapMutations("MyBooks", ["succeeded"]),
    async downBook(id, name) {
      let eventTarget = event.target;
      eventTarget.classList.add("active");
      const headers = { "Content-Type": "application/json", Authorization: `Bearer ${this.user.user.token}` };
      await fetch(`https://backend.qiyas.co/api/book/${id}/download`, {
        method: "post",
        headers: headers,
      })
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = name;
          a.click();
        })
        .finally(() => {
          eventTarget.classList.remove("active");
        });
    },
    /* switchTaps() {
      $(function () {
        let buttons = $(".list-group-button button");
        buttons.on("click", function (e) {
          $(this).addClass("active").siblings().removeClass("active");
          $("." + $(this).attr("data-class"))
            .siblings(":not('.aside--left')")
            .fadeOut(500);
          $("." + $(this).attr("data-class")).fadeIn(500);
        });
      });
    }, */
  },
};
</script>

<style lang="scss">
.holder.active {
  height: 52vh;
}
.my-books-style {
  box-shadow: 6px 6px 10px #cbced1, -10px -10px 10px #00000017;
  border-radius: 10px;
  height: 470px;
  > div {
    border-radius: inherit;
  }
}
.worning-title {
  font-size: 13px;
}
</style>
