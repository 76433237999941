import axios from "axios";
export default {
  namespaced: true,
  state: {
    rates: false,
  },
  mutations: {
    getRates(state, payload) {
      state.rates = payload;
    },
  },
  actions: {
    async GET_RATES({ commit, state }) {
      if (!state.rates.length > 0) {
        await axios.get(`/rates`).then((res) => {
          commit("getRates", res.data.rates);
        });
      }
    },
  },
};
